import './App.css';
import couch1 from './couches/WithCouch.png';
import couch2 from './couches/WithCouch2.jpeg';
import couch3 from './couches/BunkCouch.jpg';
import couch4 from './couches/VampireCouch.png';
import nocouch from './WithoutCouch.jpg';
import {useState} from "react";

const IMAGE_HEIGHT = 300;
const IMAGE_WIDTH = 400;

const BUTTON_STYLE = {
  border: 0,
  fontSize: 16,
  margin: 12,
  padding: "8px 16px",
}

const couches = [couch1, couch2, couch3, couch4]

function App() {
  const [offsets, setOffsets] = useState([])
  const [couch, setCouch] = useState([couch2])
  const [isHoveringOverNoCouch, setIsHoveringOverNoCouch] = useState(false)

  return (
    <div className="App">
      <header className="App-header">
        <div style={{position: "fixed", top: 0, left: 0}}>
          <button onClick={doFunStuff} style={BUTTON_STYLE}>Upvote</button>
          <button onClick={() => alert("Nice try, idiot")} style={BUTTON_STYLE}>Downvote</button>
        </div>
        <Couch couch={couch} />
        {offsets.map(([left, top, zoom]) => <Couch left={left} top={top} zoom={zoom} couch={couch} />)}
        <div style={{paddingTop:10}}>
          <p>
            <br></br>
            <br></br>
            Boffice Couch 2024
          </p>
        </div>
        <div>
          <button onClick={newCouch} style={BUTTON_STYLE}>New Couch Please</button>
          <button
            onClick={noCouch}
            onMouseOver={() => setIsHoveringOverNoCouch(true)}
            onMouseLeave={() => setIsHoveringOverNoCouch(false)}
            style={BUTTON_STYLE}
          >
            {isHoveringOverNoCouch ? 'Bad Idea' : 'No Couch'}
          </button>
        </div>
      </header>
    </div>
  );

  function doFunStuff() {
    setOffsets((_offsets) => {
      return [
        ..._offsets,
        [
          _randomBetween(-(IMAGE_WIDTH / 2), window.innerWidth - (IMAGE_WIDTH / 2)),
          _randomBetween(-(IMAGE_HEIGHT / 2), window.innerHeight - (IMAGE_HEIGHT / 2)),
          _randomBetween(0.1, 1),
        ]
      ]
    })
  }

  function _randomBetween(min, max) {
    return Math.random() * (max - min + 1) + min
  }

  function _randomIntegerBetween(min, max) {
    return Math.floor(_randomBetween(min, max))
  }

  function newCouch() {
    const index = _randomIntegerBetween(0, 3)
    let nextCouch = couches[index]
    if (nextCouch === couch) {
      nextCouch = couches[(index + 1) % 4]
    }
    setCouch(nextCouch)
  }

  function noCouch() {
    setCouch(nocouch)
  }
}

function Couch(props) {
  const {left, top, zoom = 1, couch} = props

  let style = {
    height: IMAGE_HEIGHT * zoom,
    width: IMAGE_WIDTH * zoom,
  }
  if (left && top) {
    style = {
      ...style,
      left,
      position: "absolute",
      top,
    }
  }
  return (
    <img
      alt="logo"
      className="App-logo"
      key={`${left}${top}`}
      src={couch}
      style={style}
    />
  )
}

export default App;
